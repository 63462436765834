<template>
	<div v-if="companyDescription.length">
		<div
			v-for="(about, index) in companyDescription"
			:key="index"
		>
			<div v-if="about.textunit">
				<section class="section wow">
					<div class="content">
						<h3 class="content__title">
							{{ about.title_unit }}
						</h3>
						<div
							class="text"
							v-html="about.text_unit"
						/>
					</div>
				</section>
			</div>

			<div v-if="about.photounit">
				<section class="section wow">
					<div class="content">
						<div class="content__pic">
							<a
								v-if="about.photo_url"
								:href="about.photo_url"
								target="_blank"
							>
								<img
									:src="about.photo"
									alt="Картинка компании"
								>
							</a>
							<img
								v-else
								:src="about.photo"
								alt="Картинка компании"
							>
						</div>
					</div>
				</section>
			</div>

			<div v-if="about.buttonunit">
				<section class="section wow">
					<div class="content">
						<div :style="{textAlign: about.align}">
							<a
								v-if="about.target_blank"
								:href="about.url_button"
								target="_blank"
								class="button"
								:class="about.colour_button === 'orange' ? 'orange' : 'blue'"
							>
								{{ about.text_button }}
							</a>
							<a
								v-else
								:href="about.url_button"
								class="button"
								:class="about.colour_button === 'orange' ? 'orange' : 'blue'"
							>
								{{ about.text_button }}
							</a>
						</div>
					</div>
				</section>
			</div>

			<div v-if="about.informerunit && about.info.length">
				<section class="section blue wow">
					<div class="content">
						<div class="info">
							<div
								v-for="(informer, ind) in about.info"
								:key="ind"
								class="info__item"
							>
								<div
									v-if="informer.photo"
									class="info__icon"
								>
									<img
										:src="informer.photo"
										alt="Информер компании"
									>
								</div>
								<div class="info__text">
									<p>{{ informer.text }}</p>
								</div>
							</div>
						</div>
					</div>
				</section>
			</div>

			<section
				v-if="about.sliderunit && about.photos.length"
				class="section wow"
			>
				<div class="content">
					<VueSlickCarousel
						class="slider"
						v-bind="carouselCompanySettings"
					>
						<div
							v-for="(image, ind) in about.photos"
							:key="ind"
						>
							<div class="slider__slide">
								<img
									:src="image"
									alt="Слайд компании"
								>
							</div>
						</div>
					</VueSlickCarousel>
				</div>
			</section>
		</div>
	</div>
</template>

<script>
import {mapActions} from 'vuex';

export default {
    name: 'About',
    data: () => ({
        companyDescription: [],
        carouselCompanySettings: {
            arrows: true,
            dots: true,
            infinite: false,
            'slides-to-show': 1,
            'slides-to-scroll': 1,
            draggable: false,
            speed: 600,
            swipe: true,
            'touch-move': true,
            responsive : [
                {
                    breakpoint: 1099,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 640,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        },
    }),
    created() {
        this.getCompany(this.$route.params.id).then(company => {
            this.companyDescription = company.description
            return this.isLoaded = false
        })
    },
    methods: {
        ...mapActions('cmp', [
            'getCompany'
        ])
    }
}
</script>

<style lang="less">
.content {
    position: relative;
    width: 100%;
    max-width: 1080px;
    margin-right: auto;
    margin-left: auto;
    padding: 0 30px;
    &__title {
        margin-bottom: 30px;
        font-size: 27px;
        font-weight: 400;
    }
    @media (max-width: 1099px) {
        &__title {
            font-size: 24px;
        }
    }
    @media (max-width: 767px) {
        &__title {
            margin-bottom: 25px;
            font-size: 22px;
        }
    }
    &__pic{
        position: relative;
        font-size: 0;
        img{
            display: block;
            width: 100%;
        }
        &:after {
            background: none;
        }
    }
}
.wow {
    opacity: 1
}
.info{
    display: flex;
    flex-wrap: nowrap;
    align-items: flex-start;
    justify-content: space-between;
    @media @desktop-end{
    }
    @media @laptop-end{
    }
    @media @tablet-end{
    }
    @media @mobile-end{
        display: block;
        margin-bottom: -35px;
        text-align: center;
    }
    &__item {
        max-width: 33.33%;
        padding-right: 30px;
        @media @desktop-end{
        }
        @media @laptop-end{
        }
        @media @tablet-end{
        }
        @media @mobile-end{
            max-width: 100%;
            margin-bottom: 35px;
            padding-right: 0;
        }
    }
    &__text {
        font-size: 18px;
        color: @color-text;
        @media @desktop-end{
        }
        @media @laptop-end{
        }
        @media @tablet-end{
            font-size: 16px;
        }
        @media @mobile-end{
        }
    }
    &__icon{
        height: 67px;
        line-height: 60px;
        margin-bottom: 30px;
        object-fit: contain;
        @media @desktop-end{
        }
        @media @laptop-end{
        }
        @media @tablet-end{
            margin-bottom: 20px;
        }
        @media @mobile-end{
            margin-bottom: 10px;
        }
        img{
            display: inline-block;
            vertical-align: middle;
            width: 80px;
            height: 70px;
            object-fit: contain
        }
    }
}

.slider{
    margin-bottom: 20px;
    .slick-arrow{
        top: 50%;
        margin-top: -45px;
        &.slick-prev{
            left: -75px;
            @media @desktop-end{
            }
            @media @laptop-end{
                left: -30px;
            }
            @media @tablet-end{
                left: -15px;
            }
            @media @mobile-end{
                left: -25px;
            }
        }
        &.slick-next{
            right: -75px;
            @media @desktop-end{
            }
            @media @laptop-end{
                right: -30px;
            }
            @media @tablet-end{
                right: -15px;
            }
            @media @mobile-end{
                right: -25px;
            }
        }
    }
    .slick-dots{
        position: absolute;
        margin-top: 15px;
        margin-bottom: -5px;
        display: inline-block;
        width: 50%;
        left: 50%;
        transform: translate(-50%);
    }
    &__slide img {
        height: 547px;
        width: 100%;
    }
}
.button {
    height: 100%;
}
</style>
